import { MenuItem } from 'interfaces/MenuItem'
import { useRouter } from 'next/router'
import { useState, useEffect, Dispatch } from 'react'

const useNavSelected = <T extends MenuItem>(
  navItems: T[],
  defaultSelected: number | null = null
): { selected: number; setSelected: Dispatch<number> } => {
  const router = useRouter()
  const [selected, setSelected] = useState(defaultSelected)

  // remove any query parameters from the current url
  const asPath = router?.asPath?.split('?')[0]

  useEffect(() => {
    if (navItems) {
      navItems.forEach((item, index) => {
        if (
          typeof item.as !== 'string' &&
          item.as?.pathname &&
          item.as.pathname === asPath
        ) {
          setSelected(index)
        } else if (item.as === asPath) {
          setSelected(index)
        } else if (!item.as && item.href === router.pathname) {
          setSelected(index)
        } else if (!item.as && item.href === asPath) {
          setSelected(index)
          // this case is needed for pages like /user/inbox?stat=2
        } else if (!item.as && item.href === router?.asPath) {
          setSelected(index)
        } else if (item.additionalSelectedPaths?.includes(asPath)) {
          setSelected(index)
        } else if (!item.href && item.listMenuItems) {
          if (
            item.listMenuItems.some(
              (listMenuItem) => listMenuItem.href === asPath
            )
          )
            setSelected(index)
        } else if (
          item.matchAsPrefix &&
          asPath.startsWith(item.href as string)
        ) {
          setSelected(index)
        }
      })
    }
  }, [router, navItems, asPath])

  return { selected, setSelected }
}

export default useNavSelected
