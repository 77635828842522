import { Box, Alignment, Tag } from '@resident-advisor/design-system'
import { hrefAsPropTypes } from 'components/generic/link'
import animation from 'themes/animation'
import PropTypes, { InferProps } from 'prop-types'
import { dark } from 'themes'
import { useIntl } from 'react-intl'
import pro from 'messages/pro'
import SubNavMenuItemType from 'enums/sub-nav-menu-item-type'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import SubNavLink from './SubNavLink'
import Underline from './Underline'

const SingleSubNavItem: React.FC<SingleSubNavItemProps> = ({
  item,
  index,
  selected,
  hovered,
  setHovered,
  theme,
}) => {
  const intl = useIntl()
  const enableNewTagVisibility = useFeatureSwitch(
    featureSwitches.enableNewTagVisibility
  )

  return (
    <Box
      onMouseEnter={() => setHovered(index)}
      onMouseLeave={() => setHovered(null)}
    >
      <SubNavLink
        theme={theme}
        isSelected={index === selected}
        href={item.href}
        as={item.as}
        target={item.target}
        data-testid="subnavlink"
      >
        {index === (theme === dark ? selected : hovered) && (
          <Underline
            layoutId="underline"
            transition={animation.default.closed}
            data-testid="underline"
          />
        )}
        {item.title}
        {item.type === SubNavMenuItemType.new && enableNewTagVisibility && (
          <Alignment justifyContent="flex-end" ml={2}>
            <Tag>{intl.formatMessage(pro.newTag)}</Tag>
          </Alignment>
        )}
      </SubNavLink>
    </Box>
  )
}

const SingleSubNavItemPropTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: hrefAsPropTypes,
    as: hrefAsPropTypes,
    target: PropTypes.string,
    additionalSelectedPaths: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.number,
  hovered: PropTypes.number,
  setHovered: PropTypes.func,
  theme: PropTypes.object,
}

SingleSubNavItem.propTypes = SingleSubNavItemPropTypes
type SingleSubNavItemProps = InferProps<typeof SingleSubNavItemPropTypes>

export default SingleSubNavItem
