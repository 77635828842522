import PropTypes from 'prop-types'
import { Alignment, variants } from '@resident-advisor/design-system'
import Link, { hrefAsPropTypes } from 'components/generic/link'
import styled, { css } from 'styled-components'
import { dark } from 'themes'
import StyledNavLink from './StyledNavLink'

const SubNavLink = ({ href, as, isSelected, target, children, theme }) => {
  return (
    <>
      {theme === dark ? (
        <StyledSubNavLink
          href={href}
          forwardedAs={as}
          role="tab"
          variant={variants.text.small}
          isSelected={isSelected}
          target={target || ''}
          rel={target ? 'noopener' : ''}
        >
          <Alignment py={3} mr={4} alignItems="center" position="relative">
            {children}
          </Alignment>
        </StyledSubNavLink>
      ) : (
        <StyledNavLink
          href={href}
          forwardAs={as}
          isSelected={isSelected}
          target={target}
        >
          <Alignment py={3} mr={4} alignItems="center" position="relative">
            {children}
          </Alignment>
        </StyledNavLink>
      )}
    </>
  )
}

SubNavLink.propTypes = {
  href: hrefAsPropTypes,
  as: hrefAsPropTypes,
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  target: PropTypes.string,
  theme: PropTypes.object,
}

const StyledSubNavLink = styled(Link)`
  ${({ theme, isSelected }) =>
    css`
      font-weight: ${theme.subNav.fontWeight};
      white-space: nowrap;
      color: ${isSelected
        ? theme.subNav.selectedColor
        : theme.subNav.defaultColor};
      &:hover {
        color: ${theme.subNav.hoverColor};
      }
    `};
`

export default SubNavLink
