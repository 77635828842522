import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import canUseDOM from 'lib/canUseDom'

// This component is needed in order for us to be able to position the cookie script banner above the top nav.
// The script that renders the consent banner is injected by Google Tag Manager.
const CookieConsentBanner = () => {
  useEffect(() => {
    const repositionCookieBanner = () => {
      // Move the injected banner into the top nav; this is needed
      // so it doesn't overlay our global nav bar & hide nav items
      const wrapper = document.getElementById('cookie-script-wrapper')
      const banner = document.getElementById('cookiescript_injected_wrapper')

      if (wrapper && banner) {
        wrapper.appendChild(banner)
      }
    }

    if (canUseDOM() && window) {
      window.addEventListener('CookieScriptLoaded', repositionCookieBanner)
    }

    return () => {
      if (canUseDOM() && window) {
        window.removeEventListener('CookieScriptLoaded', repositionCookieBanner)
      }
    }
  }, [])

  return (
    <CookieWrapper
      data-testid="cookie-script-wrapper"
      id="cookie-script-wrapper"
    />
  )
}

const CookieWrapper = styled.div`
  ${({ theme }) =>
    css`
      background-color: ${theme.colors.black};
    `}
`

export default CookieConsentBanner
