import { ComponentType, PropsWithChildren } from 'react'
import css from '@styled-system/css'
import { variants } from '@resident-advisor/design-system'
import Link from 'components/generic/link'
import styled, { css as styledCss } from 'styled-components'
import { UrlObject } from 'url'

const buildLinkStyles = ({
  isSelected,
  hoverColor = 'primary',
}: {
  isSelected?: boolean
  hoverColor?: string
}) => {
  const styles = {
    whiteSpace: 'nowrap',
  }

  return isSelected
    ? { ...styles, fontWeight: 'bold' }
    : { ...styles, '&:hover': { color: hoverColor } }
}

type StyledNavLinkProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardAs?: string | ComponentType<any>
  href?: string | UrlObject
  isSelected?: boolean
  hoverColor?: string
  target?: string
}

const StyledNavLink = ({
  href,
  forwardAs,
  isSelected,
  hoverColor,
  target,
  children,
}: PropsWithChildren<StyledNavLinkProps>) => {
  return (
    <StyledLink
      href={href}
      forwardedAs={forwardAs}
      role="tab"
      variant={variants.text.small}
      color={isSelected ? 'accent' : 'primary'}
      css={css(buildLinkStyles({ hoverColor, isSelected }))}
      target={target || ''}
      rel={target ? 'noopener' : ''}
    >
      {children}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  ${({ theme }) =>
    styledCss`
      font-weight: ${theme.subNav.fontWeight};
    `};
`

export { buildLinkStyles }
export default StyledNavLink
