import { Alignment, Box, Text, variants } from '@resident-advisor/design-system'
import styled from 'styled-components'

const UATBanner = () => {
  return (
    <Box
      bg="#ff8c00"
      width="100%"
      position="fixed"
      bottom={0}
      zIndex={10}
      height={10}
      opacity={0.85}
    >
      <Alignment justifyContent="center" alignItems="end" height="100%">
        <Trapezium bg="#ff8c00" height={20} px={5}>
          <Text variant={variants.text.small}>Server Environment: UAT</Text>
        </Trapezium>
      </Alignment>
    </Box>
  )
}

const Trapezium = styled(Box)`
  clip-path: polygon(15% 0, 85% 0, 100% 100%, 0 100%);
`

export default UATBanner
