import { useEffect, useState } from 'react'

const useGetHorizontalOffset = (arrowOriginRef, boxRef) => {
  const [offset, setOffset] = useState(null)
  useEffect(() => {
    if (arrowOriginRef && arrowOriginRef.current && boxRef && boxRef.current) {
      const parentRect = boxRef.current.getBoundingClientRect()
      const arrowOriginRect = arrowOriginRef.current.getBoundingClientRect()
      const parentCentre = parentRect.right - parentRect.width / 2
      const arrowCentre = arrowOriginRect.right - arrowOriginRect.width / 2
      const result = arrowCentre - parentCentre
      setOffset(result)
    }
  }, [arrowOriginRef, boxRef])
  return offset
}

export default useGetHorizontalOffset
