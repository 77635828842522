import { useEffect, useState, useRef } from 'react'

const useStickyTimer = (time) => {
  const [position, setPosition] = useState('sticky')
  const [hover, setHover] = useState(false)
  const [startTimer, setStartTimer] = useState(false)
  const hoverRef = useRef(hover)

  useEffect(() => {
    // When hover changes (see deps) set the hoverRef to the hover value
    hoverRef.current = hover

    if (startTimer) {
      setTimeout(() => {
        // Bypass the state when the timeout was called
        // and use the value set in the ref(see above)
        if (!hoverRef.current) {
          setPosition('relative')
        }
      }, time)
    }
  }, [hover, startTimer, time])

  return [position, setHover, setStartTimer]
}

export default useStickyTimer
