import { useEffect, useRef, useState, useCallback } from 'react'
import { deviceWidths as media } from '@resident-advisor/design-system'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import getConfig from 'next/config'
import Ad from 'components/generic/ad'
import animation from 'themes/animation'
import { useMenuContext } from 'context/MenuContext'
import useStickyTimer from './useStickyTimer'

const { publicRuntimeConfig } = getConfig()

const TIMEOUT_OFFSET = 2000
const TIMEOUT_DURATION =
  parseInt(publicRuntimeConfig.STICKY_AD_DURATION) + TIMEOUT_OFFSET

const StickyAd = () => {
  const ad = useRef()
  const loadedRef = useRef()
  const [height, setHeight] = useState(null)
  const [{ showMobileMenu }] = useMenuContext()

  const [position, setHover, setStartTimer] = useStickyTimer(
    publicRuntimeConfig.STICKY_AD_DURATION
  )

  useEffect(() => {
    // Hide the ad if it doesn't load in the timeout duration
    const timer = setTimeout(() => {
      if (!loadedRef.current && ad.current) {
        ad.current.hidden = true
      }
    }, TIMEOUT_DURATION)

    return () => clearTimeout(timer)
  }, [ad, loadedRef])

  const setLoaded = useCallback(
    (values) => {
      const { event } = values
      // Check if the ad has rendered
      // If not hide the stick ad parent container
      if (event?.isEmpty) {
        ad.current.hidden = true
        return
      }

      // Use ref for loaded status
      // This is so we can check loaded inside timer above
      loadedRef.current = true
      setStartTimer(true)

      if (values.height) {
        setHeight(values.height)
      }
    },
    [setStartTimer, setHeight]
  )

  if (showMobileMenu) return null

  return (
    <StyledSticky
      className={Ad.adSlotClass} // Ensures the container is hidden by adblockers based on its class https://easylist-downloads.adblockplus.org/easylist.txt
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      position={position}
      ref={ad}
      transition={animation.default.closed}
      measuredHeight={height}
    >
      <Ad variant={Ad.variants.Leader} onSlotRender={setLoaded} />
    </StyledSticky>
  )
}

const StyledSticky = styled(motion.div)`
  top: 0;

  ${({ theme, position }) => css`
    z-index: ${theme.zIndex.stickyHeaderAd};
    position: ${position};
    background-color: ${theme.colors.black};
  `}

  ${({ measuredHeight }) =>
    measuredHeight
      ? css`
          min-height: ${measuredHeight}px;
        `
      : css`
          min-height: 100px;
        `}


  ${media.m`
   ${({ measuredHeight }) =>
     measuredHeight
       ? css`
           min-height: ${measuredHeight}px;
         `
       : css`
           min-height: 250px;
         `};
  `};
`

export default StickyAd
