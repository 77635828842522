import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const Underline = styled(motion.div)`
  width: 100%;
  height: 2px;
  position: absolute;
  ${({ theme }) =>
    css`
      background-color: ${theme.navigation.hoverColor};
      bottom: ${theme.subNav.bottom ?? '0px'};
    `};
`

export default Underline
